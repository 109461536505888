import React from "react";
import { Suspense } from "react";
import { lazy } from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const EditAdsComponent = lazy(() => import("../Components/EditAdsComponent"));
const EditAdsPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Edit Ads</title>
          </Helmet>
          <EditAdsComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default EditAdsPage;
