import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const AllAdsSearchComponent = lazy(() =>
  import("../Components/AllAdsSearchComponent")
);
const AllSearchAdsPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Searching Ads</title>
          </Helmet>
          <AllAdsSearchComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default AllSearchAdsPage;
