import { createSlice } from "@reduxjs/toolkit";

export const ProfileSlice = createSlice({
  name: "Profile",
  initialState: {
    setProfileValue: [],
    getAllUserValue: [],
    getTotalUserValue: "",
    getSingleUserValue: [],
    getAllAdminValue: [],
    getSingleAdminValue: [],
  },
  reducers: {
    setProfile: (state, action) => {
      state.setProfileValue = action.payload;
    },
    getAllUser: (state, action) => {
      state.getAllUserValue = action.payload;
    },
    getTotalUser: (state, action) => {
      state.getTotalUserValue = action.payload;
    },
    getSingleUser: (state, action) => {
      state.getSingleUserValue = action.payload;
    },
    getSingleAdmin: (state, action) => {
      state.getSingleAdminValue = action.payload;
    },
    getAllAdmin: (state, action) => {
      state.getAllAdminValue = action.payload;
    },
  },
});

export const {
  setProfile,
  getAllUser,
  getTotalUser,
  getSingleUser,
  getSingleAdmin,
  getAllAdmin,
} = ProfileSlice.actions;
export default ProfileSlice.reducer;
