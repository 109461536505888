import { createSlice } from "@reduxjs/toolkit";

export const FileUploadSlice = createSlice({
  name: "FileUpload",
  initialState: {
    file: [],
    editFile: [],
  },
  reducers: {
    loadFile: (state, action) => {
      state.file = [
        ...state.file,
        {
          original: `${process.env.REACT_APP_BASE_PHOTO_URL}/${action.payload}`,
          thumbnail: `${process.env.REACT_APP_BASE_PHOTO_URL}/${action.payload}`,
        },
      ];
    },
    filterFile: (state, action) => {
      state.file = action.payload;
      // state.file = [{ original: action.payload, thumbnail: action.payload }];
    },
    loadEditFile: (state, action) => {
      state.editFile = [
        ...state.editFile,
        {
          original: `${process.env.REACT_APP_BASE_PHOTO_URL}/${action.payload}`,
          thumbnail: `${process.env.REACT_APP_BASE_PHOTO_URL}/${action.payload}`,
        },
      ];
    },
    filterEditFile: (state, action) => {
      state.editFile = action.payload;
    },
  },
});

export const { loadFile, filterFile, loadEditFile, filterEditFile } =
  FileUploadSlice.actions;
export default FileUploadSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// export const FileUploadSlice = createSlice({
//   name: "FileUpload",
//   initialState: {
//     file: [],
//     editFile: [],
//   },
//   reducers: {
//     loadFile: (state, action) => {
//       state.file = [...state.file, action.payload];
//     },
//     filterFile: (state, action) => {
//       state.file = action.payload;
//     },
//     loadEditFile: (state, action) => {
//       state.editFile = [...state.editFile, action.payload];
//     },
//     filterEditFile: (state, action) => {
//       state.editFile = action.payload;
//     },
//   },
// });

// export const { loadFile, filterFile, loadEditFile, filterEditFile } =
//   FileUploadSlice.actions;
// export default FileUploadSlice.reducer;
