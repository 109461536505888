import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const MyProfileComponent = lazy(() =>
  import("../Components/MyProfileComponent")
);
const MyProfilePage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || My Profile</title>
          </Helmet>
          <MyProfileComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default MyProfilePage;
