import React from "react";
import { Suspense } from "react";
import { lazy } from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
const ForgetPasswordComponent = lazy(() =>
  import("../Components/ForgetPasswordComponent")
);
const ForgetPage = () => {
  return (
    <Fragment>
      <>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Forget Password</title>
          </Helmet>
          <ForgetPasswordComponent />
        </Suspense>
      </>
    </Fragment>
  );
};

export default ForgetPage;
