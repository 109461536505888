import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const HomeComponents = lazy(() => import("../Components/HomeComponents"));
const HomePage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Home</title>
          </Helmet>
          <HomeComponents />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default HomePage;
<h2>Home</h2>;
