import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";
const FullScreenLoader = () => {
  TopBarProgress.config({
    barColors: {
      0: "#6b21a8",
      "1.0": "#7e22ce",
    },
    shadowBlur: 4,
  });
  return (
    <div>
      <TopBarProgress />
    </div>
  );
};

export default FullScreenLoader;
