import React from "react";
import { Link, useParams } from "react-router-dom";
import { filterSearch__By__Category } from "../../API/API";

const SideBarCategory = () => {
  const params = useParams();
  const categoryHandel = (item) => {
    filterSearch__By__Category(item, 1);
  };
  return (
    <div className="category mt-4">
      <h2 className="text-slate-700 font-semibold text-sm">All Categories:</h2>
      <ul className="mt-2">
        <Link
          onClick={() => categoryHandel("Mobiles")}
          to={`/all-ads-filter-category/Mobiles/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Mobiles</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Electronics")}
          to={`/all-ads-filter-category/Electronics/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Electronics</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Home & Living")}
          to={`/all-ads-filter-category/Home & Living/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Home & Living</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Pets & Animals")}
          to={`/all-ads-filter-category/Pets & Animals/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Pets & Animals</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Property")}
          to={`/all-ads-filter-category/Property/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Property</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Hobbies")}
          to={`/all-ads-filter-category/Hobbies/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Hobbies</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Sports & Kids")}
          to={`/all-ads-filter-category/Sports & Kids/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Sports & Kids</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Men's Fashion & Grooming")}
          to={`/all-ads-filter-category/Men's Fashion & Grooming/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Men's Fashion & Grooming</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Women's Fashion & Beauty")}
          to={`/all-ads-filter-category/Women's Fashion & Beauty/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Women's Fashion & Beauty</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Business & Industry")}
          to={`/all-ads-filter-category/Business & Industry/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Business & Industry</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Education")}
          to={`/all-ads-filter-category/Education/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Education</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Jobs")}
          to={`/all-ads-filter-category/Jobs/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Jobs</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Essentials")}
          to={`/all-ads-filter-category/Essentials/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Essentials</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Services")}
          to={`/all-ads-filter-category/Services/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Services</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Agriculture")}
          to={`/all-ads-filter-category/Agriculture/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Agriculture</li>
        </Link>
        <Link
          onClick={() => categoryHandel("Jobs")}
          to={`/all-ads-filter-category/Jobs/1`}
          className="block text-sm text-slate-400 hover:text-slate-500 py-0.5"
        >
          <li>Overseas Jobs</li>
        </Link>
      </ul>
    </div>
  );
};

export default SideBarCategory;
