import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const AllAdsComponent_CategoryFilter = lazy(() =>
  import("../Components/AllAdsComponent_CategoryFilter")
);
const AllAdsPageFilterCategoryPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || All Ads Catagory Filter</title>
          </Helmet>
          <AllAdsComponent_CategoryFilter />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default AllAdsPageFilterCategoryPage;
