import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import AllAdsComponentFilter from "../Components/AllAdsComponentFilter";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const AllAdsComponent = lazy(() => import("../Components/AllAdsComponent"));
const AllAdsPageFilter = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || All Ads Filter</title>
          </Helmet>
          <AllAdsComponentFilter />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default AllAdsPageFilter;
