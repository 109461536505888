import React from "react";
import { Suspense } from "react";
import { lazy } from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const AdminDashboardComponent = lazy(() =>
  import("../Components/AdminDashboardComponent")
);
const AdminDashboardPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Admin Dashboard</title>
          </Helmet>
          <AdminDashboardComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default AdminDashboardPage;
