import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
const LoginComponent = lazy(() => import("../Components/LoginComponent"));
const LoginPage = () => {
  return (
    <Fragment>
      <>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Login</title>
          </Helmet>
          <LoginComponent />
        </Suspense>
      </>
    </Fragment>
  );
};

export default LoginPage;
