import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import AdminDashboardPage from "./Pages/AdminDashboardPage";
import AdminLoginPage from "./Pages/AdminLoginPage";

import AllAdsPage from "./Pages/AllAdsPage";
import EditAdsPage from "./Pages/EditAdsPage";
import ErrorPage from "./Pages/ErrorPage";
import ForgetPage from "./Pages/ForgetPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import MyProfilePage from "./Pages/MyProfilePage";
import OPTPage from "./Pages/OPTPage";
import RegistrationPage from "./Pages/RegistrationPage";
import SingleAdsPage from "./Pages/SingleAdsPage";
import store from "./Redux/Store/Store";
import { getToken, getUserRole } from "./Helper/SessionHelper";
import AllAdsPageFilter from "./Pages/AllAdsPageFilter";
import AllAdsPageFilterCategoryPage from "./Pages/AllAdsPageFilterCategoryPage";
import AllSearchAdsPage from "./Pages/AllSearchAdsPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import Loader from "./MasterLayout/Loader";
function App() {
  if (getToken() && getUserRole() === "admin") {
    return (
      <div className="App">
        <Provider store={store}>
          <div className="root">
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<HomePage />} />

                <Route
                  exact
                  path="/all-ads-filter/:district/:subDistricts/:category/:pageNo"
                  element={<AllAdsPageFilter />}
                />
                <Route exact path="/all-ads/:pageNo" element={<AllAdsPage />} />
                <Route
                  exact
                  path="/search/:searchKeyword/:pageNo"
                  element={<AllSearchAdsPage />}
                />
                <Route
                  exact
                  path="/all-ads-filter-category/:category/:pageNo"
                  element={<AllAdsPageFilterCategoryPage />}
                />
                <Route
                  exact
                  path="/single-ads/:id"
                  element={<SingleAdsPage />}
                />
                <Route exact path="/admin-login" element={<AdminLoginPage />} />
                <Route
                  exact
                  path="/admin-dashboard"
                  element={<AdminDashboardPage />}
                />

                <Route exact path="*" element={<ErrorPage />} />
              </Routes>
            </BrowserRouter>
            <Toaster position="bottom-center" reverseOrder={false} />
            <ToastContainer />
          </div>
          <Loader />
        </Provider>
      </div>
    );
  } else if (getToken()) {
    return (
      <div className="App">
        <Provider store={store}>
          <div className="root">
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/all-ads/:pageNo" element={<AllAdsPage />} />
                <Route
                  exact
                  path="/all-ads-filter/:district/:subDistricts/:category/:pageNo"
                  element={<AllAdsPageFilter />}
                />
                <Route
                  exact
                  path="/all-ads-filter-category/:category/:pageNo"
                  element={<AllAdsPageFilterCategoryPage />}
                />
                <Route
                  exact
                  path="/single-ads/:id"
                  element={<SingleAdsPage />}
                />
                <Route
                  exact
                  path="/search/:searchKeyword/:pageNo"
                  element={<AllSearchAdsPage />}
                />
                <Route exact path="/admin-login" element={<AdminLoginPage />} />

                <Route exact path="/login" element={<LoginPage />} />
                <Route
                  exact
                  path="/registration"
                  element={<RegistrationPage />}
                />
                <Route exact path="/profile" element={<MyProfilePage />} />
                <Route exact path="/edit-ads/:id" element={<EditAdsPage />} />
                <Route exact path="/forget-password" element={<ForgetPage />} />
                <Route exact path="/verify-otp" element={<OPTPage />} />
                <Route
                  exact
                  path="/reset-password"
                  element={<ResetPasswordPage />}
                />
                <Route exact path="*" element={<ErrorPage />} />
              </Routes>
            </BrowserRouter>
            <Toaster position="bottom-center" reverseOrder={false} />
            <ToastContainer />
          </div>
          <Loader />
        </Provider>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Provider store={store}>
          <div className="root">
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/all-ads/:pageNo" element={<AllAdsPage />} />
                <Route
                  exact
                  path="/all-ads-filter/:district/:subDistricts/:category/:pageNo"
                  element={<AllAdsPageFilter />}
                />
                <Route
                  exact
                  path="/all-ads-filter-category/:category/:pageNo"
                  element={<AllAdsPageFilterCategoryPage />}
                />
                <Route
                  exact
                  path="/single-ads/:id"
                  element={<SingleAdsPage />}
                />
                <Route
                  exact
                  path="/search/:searchKeyword/:pageNo"
                  element={<AllSearchAdsPage />}
                />
                <Route exact path="/admin-login" element={<AdminLoginPage />} />

                <Route exact path="/login" element={<LoginPage />} />
                <Route
                  exact
                  path="/registration"
                  element={<RegistrationPage />}
                />
                <Route exact path="/profile" element={<MyProfilePage />} />
                <Route exact path="/forget-password" element={<ForgetPage />} />
                <Route exact path="/verify-otp" element={<OPTPage />} />
                <Route
                  exact
                  path="/reset-password"
                  element={<ResetPasswordPage />}
                />
                <Route exact path="*" element={<ErrorPage />} />
              </Routes>
            </BrowserRouter>
            <Toaster position="bottom-center" reverseOrder={false} />
            <ToastContainer />
          </div>
          <Loader />
        </Provider>
      </div>
    );
  }
}

export default App;
