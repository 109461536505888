import toast from "react-hot-toast";

let emailRegx = /\S+@\S+\.\S+/;
let mobileRegx = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
let numberRegx = /^[0-9]+$/;
class FormHelper {
  IsEmpty(value) {
    return value.length === 0;
  }
  IsUndefined(value) {
    return value === undefined;
  }
  IsEmail(value) {
    return !emailRegx.test(value);
  }
  IsMobile(value) {
    return !mobileRegx.test(value);
  }
  IsNumber(value) {
    return !numberRegx.test(value);
  }
  ErrorTost(msg) {
    toast.error(msg, {
      style: {
        borderRadius: "5px",
        background: "#374151",
        color: "#fff",
      },
    });
  }
  SuccessTost(msg) {
    toast.success(msg, {
      style: {
        borderRadius: "5px",
        background: "#374151",
        color: "#fff",
      },
    });
  }
  SuccessCreateAdsTost(msg) {
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible
              ? "animate__animated animate__bounce"
              : "animate__animated animate__zoomOut"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://res.cloudinary.com/amit-js/image/upload/v1663599145/AmitJS.com/profile_img__Data/background_white_pp_size_1_kxvfi9.png"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-base font-medium text-gray-900">
                  Amit Biswas{" "}
                  <span className="bg-[#A855F7] text-xs py-0 px-2 rounded-full text-[#fff]">
                    Admin
                  </span>
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Ads Create Successful. Please Waiting For Approve.
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ),
      { duration: 5000 }
    );
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }
}

export const {
  IsEmpty,
  IsEmail,
  IsMobile,
  IsNumber,
  ErrorTost,
  SuccessTost,
  SuccessCreateAdsTost,
  getBase64,
  IsUndefined,
} = new FormHelper();
