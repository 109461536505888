import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../src/Assets/img/logo.png";
import { FaUserCog } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

import { All__ads__Pagination_ApproveBy_Admin__Request__API } from "../API/API";
import { getUserRole } from "../Helper/SessionHelper";
const MasterLayout = (props) => {
  const allAdsLoad = () => {
    All__ads__Pagination_ApproveBy_Admin__Request__API(1);
  };
  return (
    <div className="">
      <div className=" bg-[#a855f7] pt-5 pb-3 md:py-4">
        <header className="  container mx-auto">
          <nav className="flex justify-between">
            <div className="logo__section w-80 flex justify-between items-center">
              <Link to={"/"}>
                <img src={logo} alt="" className="inline-block" />
              </Link>
            </div>
            <div className="profile__section w-[480px] flex justify-end md:justify-between items-center">
              <span className="hidden md:flex items-end">
                <Link onClick={allAdsLoad} to={"/all-ads/1"}>
                  <button className="block py-2 px-8 rounded-full bg-[#FCD34D] font-semibold text-slate-900 hover:bg-[#fbbf24]">
                    <MdLocationOn className="inline" /> All Ads
                  </button>
                </Link>
              </span>
              {getUserRole() !== "admin" && (
                <Link
                  to={"/profile"}
                  className="hidden  md:block py-2 px-8 rounded-full bg-[#FCD34D] font-semibold text-slate-900 hover:bg-[#fbbf24]"
                >
                  <MdLocationOn className="inline" /> Post Your AD
                </Link>
              )}

              {getUserRole() === "admin" ? (
                <Link
                  to={"/admin-dashboard"}
                  className="hidden md:block text-slate-900 font-semibold p-2 transition-primary btn-secondary"
                >
                  <FaUserCog className="inline-block " /> Admin Profile
                </Link>
              ) : (
                <Link
                  to={"/profile"}
                  className="hidden md:block text-slate-900 font-semibold py-2 px-4 transition-primary btn-secondary"
                >
                  <FaUserCog className="inline-block " /> My Account
                </Link>
              )}

              <Link
                to={"/profile"}
                className="md:hidden text-slate-200 font-semibold p-2 transition-primary "
              >
                <FaUserCog className="inline-block text-[30px]" />
              </Link>
            </div>
          </nav>
          <div className="flex md:hidden mt-5 justify-center">
            <Link onClick={allAdsLoad} to={"/all-ads/1"}>
              <button className="block py-2 px-8 rounded-full bg-[#FCD34D] font-semibold text-slate-900 hover:bg-[#fbbf24]">
                <MdLocationOn className="inline" /> All Ads
              </button>
            </Link>
          </div>
        </header>
      </div>

      <div className="main__body">{props.children}</div>
    </div>
  );
};

export default MasterLayout;
