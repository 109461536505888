import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const AllAdsComponent = lazy(() => import("../Components/AllAdsComponent"));
const AllAdsPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || All Ads</title>
          </Helmet>
          <AllAdsComponent />
          {/* <h2>Hello</h2> */}
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default AllAdsPage;
