import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
import MasterLayout from "../MasterLayout/MasterLayout";
const ErrorComponents = lazy(() => import("../Components/ErrorComponents"));
const ErrorPage = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Error 404</title>
          </Helmet>
          <ErrorComponents />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default ErrorPage;
