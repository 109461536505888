import { createSlice } from "@reduxjs/toolkit";

export const LoaderSettingSlice = createSlice({
  name: "LoaderSetting",
  initialState: {
    loader: "leading-none",
  },
  reducers: {
    showLoader: (state) => {
      state.loader = "";
    },
    hideLoader: (state) => {
      state.loader = "leading-none";
    },
  },
});

export const { showLoader, hideLoader } = LoaderSettingSlice.actions;
export default LoaderSettingSlice.reducer;
