import React, { useState } from "react";
import {
  FaFacebookF,
  FaGit,
  FaLinkedinIn,
  FaLocationArrow,
  FaMediumM,
  FaSearch,
} from "react-icons/fa";
import amit from "../Assets/img/amit.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import Select from "react-select";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  filterSearch__By__Select,
} from "../API/API";
import ReactPaginate from "react-paginate";
import store from "../Redux/Store/Store";
import {
  getPaginationAllAds_Approve_ByAdmin,
  paramsAds,
} from "../Redux/StateSlice/AdsSlicer";
import SideBarCategory from "./ChildComponents/SideBarCategory";
import { MdLocationOn } from "react-icons/md";
import { useRef } from "react";
import { ErrorTost, IsEmpty } from "../Helper/FormHelper";
const AllAdsComponentFilter = () => {
  let navigate = useNavigate();
  const params = useParams();

  let Districts = params.district;
  let subDistricts = params.subDistricts;
  let Category = params.category;
  useEffect(() => {
    store.dispatch(getPaginationAllAds_Approve_ByAdmin([]));
    filterSearch__By__Select(Districts, subDistricts, Category, params.pageNo);
  }, []);

  const handelPageClick = (event) => {
    let pageNo = event.selected;
    filterSearch__By__Select(
      Districts,
      subDistricts,
      Category,
      pageNo + 1
    ).then((res) => {
      if (res === true) {
        store.dispatch(paramsAds(pageNo + 1));
        navigate(
          `/all-ads-filter/${Districts}/${subDistricts}/${Category}/${
            pageNo + 1
          }`
        );
      }
    });
  };

  const get_Pagination_All_Ads_Data = useSelector(
    (state) => state.Ads.getPaginationAllAds_Approve_ByAdmin_Data
  );
  const get_Total_All_Ads_Data = useSelector(
    (state) => state.Ads.getTotalAds_Approve__ByAdmin_Data
  );

  const filterAds = get_Pagination_All_Ads_Data.filter(
    (item) => item?.Approve === true
  );

  const sort = [
    { value: "Latest", label: "Date : Latest" },
    { value: "Old", label: "Date : Old" },
    { value: "High", label: "Price: High to low" },
    { value: "Low", label: "Price: Low to high" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  var newFilterBySort;
  if (selectedOption?.value === "Latest") {
    newFilterBySort = filterAds;
  } else if (selectedOption?.value === "Old") {
    newFilterBySort = filterAds.reverse();
  } else if (selectedOption?.value === "High") {
    newFilterBySort = filterAds.sort((a, b) => {
      return b?.Price - a?.Price;
    });
  } else if (selectedOption?.value === "Low") {
    newFilterBySort = filterAds.sort((a, b) => {
      return a?.Price - b?.Price;
    });
  }

  //! Search Handel
  let searchRef = useRef();
  const searchHandel = () => {
    let searchKeyword = searchRef.value;
    if (IsEmpty(searchKeyword)) {
      ErrorTost("Please Enter Search Content!");
    } else {
      navigate(`/search/${searchKeyword}/1`);
    }
  };
  return (
    <section className="bg-[#f3f4f6] min-h-screen block pt-8">
      <div className="container  mx-auto mb-20">
        {/* Intro Section */}
        <div className="bg-[#fff] rounded-md pb-10">
          <div className="body__option py-5 px-5   ">
            <div className="safe__bar bg-[#6366f1] rounded-md md:flex justify-between gap-8 py-4">
              <div className="text__file px-4 md:px-0 md:ml-4  ">
                <p className="flex justify-center  text-slate-600 font-semibold bg-[#FCD34D] px-2 py-0.5 rounded-full text-sm">
                  Get Safe Browser in your Location!
                </p>
              </div>
              <div className="flex justify-center mt-2 md:block img__file text-sm text-white font-semibold  mr-4">
                <p>
                  <Link to={"/"}>Home</Link> / All Ads
                </p>
              </div>
            </div>
          </div>
          {/* Main Body */}
          <div className="allAds px-5 py-2">
            {/* Search Option */}
            <div className="search__option md:flex  items-center mb-3">
              <div className="hidden md:block item w-1/4">
                <BiCategory className="inline text-[#A855F7]" />{" "}
                <span className="text-base font-semibold text-slate-700">
                  Select Category : {params?.category}
                </span>
              </div>
              <div className="hidden md:block item w/1/4">
                <FaLocationArrow className="inline text-[#A855F7]" />{" "}
                <span className="text-base font-semibold text-slate-700">
                  Location: {params?.district}
                </span>
              </div>

              <div className="item md:w-2/4 md:ml-auto">
                <label class="block relative">
                  <input
                    type="text"
                    name="text"
                    class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-full sm:text-sm focus:ring-1"
                    placeholder="What Are You Want?"
                    ref={(input) => (searchRef = input)}
                  />
                  <div
                    className="absolute right-[6px] top-[5px] md:top-[3px] md:right-[4px] bg-[#FCD34D] p-2 rounded-full cursor-pointer"
                    onClick={searchHandel}
                  >
                    <FaSearch />
                  </div>
                </label>
              </div>
            </div>
            <hr />
            {/* Ads Show Option */}
            <div className="adsShow mt-4">
              <div className="main__show__body md:flex gap-6">
                <div className="leftSide md:w-4/5">
                  <div className="md:flex gap-10">
                  <div className="hidden md:block customsAdsData md:w-1/4 mt-7 md:mt-0">
                      <h2 className="text-slate-600 font-semibold text-sm mb-5">
                        Developer Details:
                      </h2>
                      <div className="bg-[#fff] rounded-lg px-4 py-5 drop-shadow-md">
                        <div className="img__file flex justify-between">
                          <img
                            className="w-[140px] h-[140px] rounded-full"
                            src={amit}
                            alt=""
                          />
                        </div>
                        <h2 className="mt-3 text-slate-700 font-semibold text-base">
                          Amit Biswas
                        </h2>
                        <p className=" text-slate-600 font-normal text-sm">
                          https://amitjs.com
                        </p>
                        <p className="text-slate-500 text-xs">
                          Full Stack Web Developer <br /> (MERN || NextJS ) and Web Application
                          specializing.
                        </p>
                        <div className="icons flex gap-2 mt-4">
                          <a 
                            href={"https://www.facebook.com/amitbiswas1994"}
                            className="flex justify-center items-center bg-[#1877f2] w-[25px] h-[25px] rounded-full"
                          >
                            <FaFacebookF className="text-white" />
                          </a>
                          <a
                            href={"https://github.com/amitbd599"}
                            className="flex justify-center items-center bg-[#191919] w-[25px] h-[25px] rounded-full"
                          >
                            <FaGit className="text-white" />
                          </a>
                          <a
                            href={"https://www.linkedin.com/in/amitbiswas-tm/"}
                            className="flex justify-center items-center bg-[#0a66c2] w-[25px] h-[25px] rounded-full"
                          >
                            <FaLinkedinIn className="text-white" />
                          </a>
                          <a
                            href={"https://medium.com/@amitbd599"}
                            target="blank"
                            className="flex justify-center items-center bg-[#191919] w-[25px] h-[25px] rounded-full"
                          >
                            <FaMediumM className="text-white" />
                          </a>
                         
                        </div>
                      </div>
                    </div>
                    <div className="MainAdsData md:w-3/4">
                      <div>
                        <p className="text-slate-600 font-semibold text-sm mb-5">
                          Total Result Showing: {get_Total_All_Ads_Data}
                        </p>
                      </div>
                      {filterAds?.length === 0 && (
                        <div className="empty__post my-10 flex justify-center">
                          <div className="block p-6 w-[400px]  transition-shadow bg-white sm:pr-12 group">
                            <span className="">
                              <img
                                className="w-[75px] h-[75px]"
                                src="https://res.cloudinary.com/amitjs/image/upload/v1664047528/Ecommerce%20Product%20Img-%20Important/Group_zxczwh.png"
                                alt=""
                              />
                            </span>

                            <h2 className="mt-3 text-lg font-bold">
                              No Searching Data Found yet!
                            </h2>

                            <p className="mt-3 text-sm text-gray-500">
                              You Can Post any Ads and If you want to delete any
                              time. All Ads You have to add Description and
                              Phone number.
                            </p>

                            <div className="mt-5">
                              <Link
                                to={"/"}
                                className="py-2 px-5 rounded-xl bg-[#FCD34D] font-semibold text-slate-700 hover:bg-[#fbbf24]"
                              >
                                <MdLocationOn className="inline" /> Go Home
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      <div>
                        {/* Items Show */}

                        {filterAds.map((item, index) => (
                          <div
                            key={index}
                            className="item border rounded-md py-2 px-2 mb-4"
                          >
                            {filterAds.length > 0 ? (
                              <Link
                                to={`/single-ads/${item._id}`}
                                className="grid sm:flex gap-5"
                              >
                                <div className="img_file md:h-[150px] sm:w-[150px] truncate">
                                  <img
                                    className="w-[100%] h-auto"
                                    src={item?.Photo[0]?.original}
                                    alt=""
                                  />
                                </div>
                                <div className="text__file ml-2 md:ml-0 sm:w-[calc(100%_-_150px)]">
                                  <div className="header__text">
                                    <h2 className="text-slate-700 font-semibold text-lg">
                                      {item?.productName}{" "}
                                      <span className="text-xs">
                                        {item?.condition &&
                                          `(${item?.condition})`}
                                      </span>
                                    </h2>
                                  </div>
                                  <div className="category">
                                    <p className="text-slate-500 text-sm">
                                      {item?.Category}
                                    </p>
                                    <p className="text-slate-500 text-sm">
                                      {item?.SubDistricts}, {item?.Districts}
                                    </p>
                                  </div>
                                  <div className="price  text-base font-semibold text-[#6366F1]">
                                    Tk {item?.Price}
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <Skeleton baseColor="#faf5ff" count={4} />
                            )}
                          </div>
                        ))}

                        {/* Pagination */}
                        <div className="pagination mt-8 mb-8 md:mt-12 md:mb-0">
                          <div className="pagination__data">
                            {get_Total_All_Ads_Data > 8 && (
                              <ReactPaginate
                                className="flex gap-2"
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="border  hover:bg-[#A855F7] hover:text-white text-slate-700 w-[35px] h-[35px] flex justify-center items-center rounded-full"
                                previousLinkClassName="border  hover:bg-[#A855F7] hover:text-white text-slate-700 w-[35px] h-[35px] flex justify-center items-center rounded-full"
                                nextLinkClassName="border  hover:bg-[#A855F7] hover:text-white text-slate-700 w-[35px] h-[35px] flex justify-center items-center rounded-full"
                                breakLabel=". . ."
                                pageCount={get_Total_All_Ads_Data / 8}
                                initialPage={parseInt(params.pageNo - 1)}
                                pageRangeDisplayed={3}
                                activeClassName="active   bg-[#A855F7] rounded-full"
                                onPageChange={handelPageClick}
                                type="button"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightSide md:w-1/5 md:border-l md:pl-5 border-slate-200">
                  <div className="sort__result">
                    <p className="text-slate-500 text-xs mb-2">
                      Sort results by
                    </p>
                    <Select
                      className="select__color"
                      defaultValue={sort[0]}
                      options={sort}
                      onChange={setSelectedOption}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          borderBottom: "1px solid #ddd",
                          color: state.isSelected ? "#fff" : "#666",
                          background: state.isSelected ? "#A855F7" : "#fff",
                          cursor: "pointer",
                          margin: "0px",
                          fontSize: "12px",
                          ":active": {
                            backgroundColor: "#fff",
                            cursor: "pointer",
                          },
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#666",

                          fontSize: "12px",
                        }),
                        control: (styles) => ({
                          ...styles,
                          backgroundColor: "#ffffff",
                          padding: "0px 0px",
                          margin: "0px 0px",
                          cursor: "pointer",
                          ":focus-within": {
                            ...styles[":focus-within"],
                            border: "1px solid #ddd",
                            boxShadow: "none",
                          },
                        }),
                        menuList: (styles) => ({
                          ...styles,
                          margin: "0px",
                          padding: "0px",
                        }),
                        noOptionsMessage: (styles) => ({
                          ...styles,
                          background: "red",
                          color: "#fff",
                        }),
                      }}
                    />
                  </div>

                  {/* Category Items */}
                  <SideBarCategory />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default AllAdsComponentFilter;
