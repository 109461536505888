import { createSlice } from "@reduxjs/toolkit";

export const AdsSlicer = createSlice({
  name: "Ads",
  initialState: {
    getAllAdsData: [],
    singleUserAllAdsData: [],
    getPaginationSingleUserAllAdsData: [],
    singleUserSingleAdsData: [],
    getPaginationAllAdsData: [],
    getPaginationAllAds_Approve_ByAdmin_Data: [],
    getTotalAds_Approve__ByAdmin_Data: [],
    getTotalAdsDataData: [],
    getTotalAllAdsData: [],
    paramsAdsData: 1,
  },
  reducers: {
    getAllAds: (state, action) => {
      state.getAllAdsData = action.payload;
    },
    getSingleUserAllAds: (state, action) => {
      state.singleUserAllAdsData = action.payload;
    },
    getPaginationSingleUserAllAds: (state, action) => {
      state.getPaginationSingleUserAllAdsData = action.payload;
    },

    getPaginationAllAds: (state, action) => {
      state.getPaginationAllAdsData = action.payload;
    },
    getPaginationAllAds_Approve_ByAdmin: (state, action) => {
      state.getPaginationAllAds_Approve_ByAdmin_Data = action.payload;
    },
    getTotalAds_Approve__ByAdmin: (state, action) => {
      state.getTotalAds_Approve__ByAdmin_Data = action.payload;
    },

    getSingleUserSingleAds: (state, action) => {
      state.singleUserSingleAdsData = action.payload;
    },
    getTotalAds: (state, action) => {
      state.getTotalAdsDataData = action.payload;
    },
    getTotalAllAds: (state, action) => {
      state.getTotalAllAdsData = action.payload;
    },
    paramsAds: (state, action) => {
      state.paramsAdsData = action.payload;
    },
  },
});

export const {
  getAllAds,
  getSingleUserAllAds,
  getSingleUserSingleAds,
  getPaginationSingleUserAllAds,
  getPaginationAllAds_Approve_ByAdmin,
  getTotalAds_Approve__ByAdmin,
  getTotalAds,
  getPaginationAllAds,
  getTotalAllAds,
  paramsAds,
} = AdsSlicer.actions;
export default AdsSlicer.reducer;
