import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
const AdminLoginComponent = lazy(() =>
  import("../Components/AdminLoginComponent")
);
const AdminLoginPage = () => {
  return (
    <Fragment>
      <>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Admin Login</title>
          </Helmet>
          <AdminLoginComponent />
        </Suspense>
      </>
    </Fragment>
  );
};

export default AdminLoginPage;
