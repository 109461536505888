import React, { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../MasterLayout/FullScreenLoader";
const ResetPassword = lazy(() => import("../Components/ResetPassword"));
const ResetPasswordPage = () => {
  return (
    <Fragment>
      <>
        <Suspense fallback={<FullScreenLoader />}>
          <Helmet>
            <title>Buy & Sell || Reset Password</title>
          </Helmet>
          <ResetPassword />
        </Suspense>
      </>
    </Fragment>
  );
};

export default ResetPasswordPage;
