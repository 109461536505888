import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "../StateSlice/ProfileSlice";
import FileUploadReducer from "../StateSlice/FileUploadSlice";
import AdsReducer from "../StateSlice/AdsSlicer";
import loaderReducer from "../StateSlice/LoaderSettingSlice";

const store = configureStore({
  reducer: {
    LoaderSetting: loaderReducer,
    profile: profileReducer,
    FileUpload: FileUploadReducer,
    Ads: AdsReducer,
  },
});

export default store;
